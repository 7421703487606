<template>
  <div class="agreements-content">
    <p><b>Effective November 1, 2022.</b></p>

    <p>This Privacy Policy describes our policies on the collection, use, and disclosure of information about you in
      connection with your use of our services, including those offered through our websites, communications (e.g.,
      emails, phone calls, and texts), and mobile applications (collectively, the “<b>Service</b>”). The terms
      “<b>we</b>”, “<b>us</b>”,
      “<b>our</b>”, and “<b>CareRide</b>” refer to CareRide, Inc., a Delaware corporation, with its headquarters in
      Orange County,
      California. When you use the Service, you consent to our collection, use, and disclosure of information about
      you as described in this Privacy Policy. Do not access or use the Service if you are unwilling or unable to
      consent to use of your information by us.</p>

    <h5>TABLE OF CONTENTS</h5>

    <ul>
      <li>
        <a v-if="isModal" href="#section-1">Information We Collect and How We Use It</a>
        <router-link v-else to="#section-1">Information We Collect and How We Use It</router-link>
      </li>
      <li>
        <a v-if="isModal" href="#section-2">Cookies</a>
        <router-link v-else to="#section-2">Cookies</router-link>
      </li>
      <li>
        <a v-if="isModal" href="#section-3">Third Parties</a>
        <router-link v-else to="#section-3">Third Parties</router-link>
      </li>
      <li>
        <a v-if="isModal" href="#section-4">Data Retention and Account Termination</a>
        <router-link v-else to="#section-4">Data Retention and Account Termination</router-link>
      </li>
      <li>
        <a v-if="isModal" href="#section-5">Children</a>
        <router-link v-else to="#section-5">Children</router-link>
      </li>
      <li>
        <a v-if="isModal" href="#section-6">Security</a>
        <router-link v-else to="#section-6">Security</router-link>
      </li>
      <li>
        <a v-if="isModal" href="#section-7">Contact Information</a>
        <router-link v-else to="#section-7">Contact Information</router-link>
      </li>
      <li>
        <a v-if="isModal" href="#section-8">Changes to This Privacy Policy</a>
        <router-link v-else to="#section-8">Changes to This Privacy Policy</router-link>
      </li>
      <li>
        <a v-if="isModal" href="#section-9">California Residents: Your California Privacy Rights</a>
        <router-link v-else to="#section-9">California Residents: Your California Privacy Rights</router-link>
      </li>
    </ul>

    <h5 v-if="isModal" id="section-1">Information We Collect and How We Use It</h5>
    <template v-else>
      <h5>Information We Collect and How We Use It</h5>
      <div id="section-1" class="content-anchor on-page"></div>
    </template>

    <p>We may collect, transmit, and store information about you in connection with your use of the Service, including
      any information you send to or through the Service. We use that information to provide the Service's
      functionality, fulfill your requests, improve the Service's quality, engage in research and analysis relating to
      the Service, personalize your experience, track usage of the Service, provide feedback to third party businesses
      that are listed on the Service, display relevant advertising, market the Service, provide customer support,
      message you, back up our systems, allow for disaster recovery, enhance the security of the Service, and comply
      with legal obligations. Even when we do not retain such information, it still must be transmitted to our servers
      initially and stored long enough to process.
    </p>


    <p><b>Information</b>: When you create a CareRide account, we store and use the information you provide during
      that process, such as the first and last name you enter, email address, and any other information you may
      provide during the account creation process, such as a gender, phone number, or birth date. We may publicly
      display the first name and last initial that you provide, as well as any photo or other content you submit
      through the account creation process, as part of your account profile. We also store and use any preferences you
      provide to personalize your user experience. You can later modify some of the account information you provide
      through your account settings. If you believe that someone has created an unauthorized account using your
      personal information, you can request its removal by flagging it.</p>

    <p><b>Transactions</b>: If you initiate a transaction through the Service, such as scheduling a ride, we will
      collect and store information you provide associated with your transaction, such as your name, phone number,
      address, email, and payment information, as well as any other information you provide relating to the
      transaction or request, in order to process your transaction, send you communications related to the
      transaction, and to facilitate future transactionsWe may also use aggregated or anonymized information regarding
      those transactions for analytics purposes and to improve CareRide ’s services. This information may be shared
      with third parties, and third parties may share such information with us, for the same purposes. When you submit
      credit card numbers and other sensitive payment information, that information is encrypted using industry
      standard technology. If you write reviews about businesses with which you transact through the Service, we may
      publicly display the fact that you transacted with those businesses in connection with such reviews.</p>

    <p><b>Activity</b>: We store information about your use of the Service, such as your search activity, the pages
      you view, the date and time of your visits, and purchases, or transactions you make through the Service. We also
      store information that your computer or mobile device may provide to us in connection with your use of the
      Service, such as your browser type, type of computer or mobile device, browser language, IP address, WiFi
      information such as SSID, mobile carrier, phone number, unique device identifier, advertising identifier,
      location (including geolocation, beacon based location, and GPS location), and requested and referring URLs and
      mobile applications. We may also receive and store your location whenever our mobile applications are running,
      including when running in the background, if you enable our mobile apps to access such information in the course
      of using the Service. You may be able to limit or disallow our use of certain data through your device or
      browser settings, for example you may be able to limit or disallow our collection, use or sharing of location
      data or mobile advertising identifiers by adjusting the settings for our applications in iOS or Android privacy
      settings.</p>

    <p><b>Sensitive Personal Information</b>: In the course of using the Service, you may choose to allow CareRide to
      collect and store sensitive personal information about you, such as your precise geolocation. You may be able to
      limit or disallow our collection, use or sharing of location data by adjusting the settings for our applications
      in iOS or Android privacy settings. You can remove content that may include sensitive personal information
      through your account settings. </p>

    <h5 v-if="isModal" id="section-2">Cookies</h5>
    <template v-else>
      <h5>Cookies</h5>
      <div id="section-2" class="content-anchor on-page"></div>
    </template>

    <p>We, and our third-party service providers, may use cookies, web beacons, tags, scripts, local shared objects
      such as HTML5 and Flash (sometimes called “flash cookies”), advertising identifiers (including mobile
      identifiers such as Apple's Identifier for Advertisers (“IDFA”) or Google's Advertising ID (“GAID”)) and similar
      technology (“Cookies”) in connection with your use of the Service, third party websites, and mobile
      applications. Cookies may contain unique identifiers, and reside, among other places, on your computer or mobile
      device, in emails we send to you, and on our web pages. Cookies may transmit information about you and your use
      of the Service, such as your browser type, search preferences, IP address, data relating to advertisements that
      have been displayed to you or that you have interacted with, and the date and time of your use. Cookies may be
      persistent or stored only during an individual session.</p>

    <p><b>Manage cookies</b><br />
      The purposes for which we use Cookies in the Service include:</p>

    <table>
      <tr>
        <th>Purpose</th>
        <th>Explanation</th>
      </tr>
      <tr>
        <td>Processes</td>
        <td>Intended to make the Service work in the way you expect. For example, we use a Cookie that tells us
          whether you have already signed up for an account.</td>
      </tr>
      <tr>
        <td>Authentication, Security, and Compliance</td>
        <td>Intended to prevent fraud, protect your data from unauthorized parties, and comply with legal
          requirements. For example, we use Cookies to determine if you are logged in.</td>
      </tr>
      <tr>
        <td>Preferences</td>
        <td>Intended to remember information about how you prefer the Service to behave and look. For example, we use
          a Cookie that tells us whether you have declined to allow us to send push notifications to your phone.</td>
      </tr>
      <tr>
        <td>Notifications</td>
        <td>Intended to allow or prevent notices of information or options that we think could improve your use of the
          Service. For example, we use a Cookie that stops us from showing you the signup notification if you have
          already seen it.</td>
      </tr>
      <tr>
        <td>Advertising</td>
        <td>Intended to make advertising more relevant to users and more valuable to advertisers. For example, we may
          use Cookies to serve you interest-based ads, such as ads that are displayed to you based on your visits to
          other websites, or to tell us if you have recently interacted with an ad.</td>
      </tr>
      <tr>
        <td>Analytics</td>
        <td>Intended to help us understand how visitors use the Service. For example, we use a Cookie that tells us
          how our search suggestions correlate to your interactions with the search page.</td>
      </tr>
    </table>

    <p>You can also set some Cookie preferences through your device or browser settings, but doing so may affect the
      functionality of the Service. The method for disabling Cookies may vary by device and browser, but can usually
      be found in your device or browser preferences or security settings. For example, iOS and Android devices each
      have settings which are designed to limit forms of ad tracking.
    </p>

  
    <h5 v-if="isModal" id="section-3">Third Parties</h5>
    <template v-else>
      <h5>Third Parties</h5>
      <div id="section-3" class="content-anchor on-page"></div>
    </template>

    <p>Third parties may share, receive or process information about you as follows:</p>

    <p>
      <b>Advertisers</b>: We may share some non-identifiable, de-identified or aggregated information from or about
      you with third parties in connection with advertising programs and data analytics.
    </p>

    <p><b>Acquired Data</b>: CareRide may acquire information enabling us to identify and contact representatives of
      local businesses from third parties. Such acquired data may be combined with other data CareRide receives from
      or about you, and used for the purposes described in Section this Privacy Policy.</p>

    <p><b>CareRide's Service Providers</b>: We rely on third-party service providers to support or provide services
      for us in connection with your use of the Service, such as providers of non-emergency medical transportation,
      website communications and hosting, security and fraud prevention, technical and customer support, tracking and
      reporting usage of the Service, quality assurance testing, payment processing, marketing, and other functions.
      We share information from or about you with these third party providers so that they can perform their services
      or complete your requests. CareRide's third party service providers may likewise share information with us that
      they obtain from or about you in connection with providing their services or completing your requests.</p>

    <p><b>CareRide's role as a Service Provider</b>: CareRide collects, receives and processes certain information
      about you on behalf of businesses for which CareRide is acting as a scheduling platform.. For example, when you
      schedule a ride through CareRide , we collect your phone number, email address, and order details on behalf of
      the business providing the transportation, and share that information with the business. CareRide does not share
      information collected or received by CareRide outside of its role as a service provider with the business or
      other third parties, unless otherwise disclosed in this Privacy Policy, or at your direction. Third parties may
      also share information about you with CareRide in order for CareRide to provide services to them. For example,
      CareRide may receive information about you for the purposes of targeting advertising, to measure ad performance,
      or to facilitate links to point of sale systems. Third parties' collection, use and disclosure of your
      information is subject to such third party's own privacy policy and any relevant terms.
    </p>

    <p><b>Aggregate or Anonymous Information</b>: We share user information in the aggregate with third parties, such
      as businesses that provide transportation services to our users. For example, we may disclose the number of
      users that we estimate visited the physical location of a particular business.</p>

    <p><b>Businesses on CareRide</b>: We may share information from or about you (such as your city, and if you
      provide it, your age and gender), your device type, and your use of the Service with businesses who provide
      transportation services through CareRide . You may adjust your account settings to increase or decrease the
      amount of information we share by emailing <a href="mailto:support@careride.com"
        target="_blank">support@careride.com</a>.</p>

    <p><b>Links</b>: The Service may link to third party-controlled websites, like a business's URL. Except as set
      forth herein, we do not share your personal information with them, and are not responsible for their privacy
      practices.</p>

    <h5 v-if="isModal" id="section-4">Data Retention and Account Termination</h5>
    <template v-else>
      <h5>Data Retention and Account Termination</h5>
      <div id="section-4" class="content-anchor on-page"></div>
    </template>

    <p>Information on how to close your account is available by emailing <a href="mailto:support@careride.com"
        target="_blank">support@careride.com</a>. We may retain
      information about you for the purposes authorized under this Privacy Policy unless prohibited by law. For
      example, we may retain information to prevent, investigate, or identify possible wrongdoing in connection with
      the Service or to comply with legal obligations. We may also maintain residual copies of your personal
      information in our backup systems. </p>

    <h5 v-if="isModal" id="section-5">Children</h5>
    <template v-else>
      <h5>Children</h5>
      <div id="section-5" class="content-anchor on-page"></div>
    </template>

    <p>The Service is intended for general audiences and is not directed to children under 13. We do not knowingly
      collect personal information from children under 13. Although use of CareRide by children is unlikely, if you
      become aware that a child has provided us with personal information without parental consent, please contact us
      at <a href="mailto:support@careride.com" target="_blank">support@careride.com</a>. If we become aware that a
      child under 13 has provided us with personal information
      without parental consent, we take steps to remove such information and terminate the child's account.</p>

    <h5 v-if="isModal" id="section-6">Security</h5>
    <template v-else>
      <h5>Security</h5>
      <div id="section-6" class="content-anchor on-page"></div>
    </template>

    <p>We use various safeguards to protect the personal information submitted to us, both during transmission and
      after we receive it. However, no method of transmission over the Internet or via mobile device, or method of
      electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect
      your personal information, we cannot guarantee its absolute security.</p>

    <h5 v-if="isModal" id="section-7">Contact Information</h5>
    <template v-else>
      <h5>Contact Information</h5>
      <div id="section-7" class="content-anchor on-page"></div>
    </template>

    <p>You may contact us online concerning our Privacy Policy at: <a href="mailto:support@careride.com"
        target="_blank">support@careride.com</a></p>

    <h5 v-if="isModal" id="section-8">Changes to This Privacy Policy</h5>
    <template v-else>
      <h5>Changes to This Privacy Policy</h5>
      <div id="section-8" class="content-anchor on-page"></div>
    </template>

    <p>
      We may update our privacy policy from time to time. We will indicate at the top of this page the date that such
      changes were last made. You are responsible for ensuring we have an up-to-date active and deliverable email
      address and/or phone number for you and for periodically visiting this privacy policy to check for any
      changes. <b>You understand and agree that your continued access to or use of the Service after the
        effective date of changes to this privacy policy represents your acceptance of such changes</b>.
    </p>

    <h5 v-if="isModal" id="section-9">California Residents: Your California Privacy Rights</h5>
    <template v-else>
      <h5>California Residents: Your California Privacy Rights</h5>
      <div id="section-9" class="content-anchor on-page"></div>
    </template>

    <p>Under the California Consumer Privacy Act of 2018 as amended by the California Privacy Rights Act of 2020
      (“<b>CCPA</b>”), California residents have certain rights around CareRide's collection, use, and sharing of
      their
      personal information.
      CareRide does not sell your personal information and will not do so in the future without providing you with
      notice and an opportunity to opt-out of such sale as required by law. Similarly, we do not offer financial
      incentives associated with our collection, use, or disclosure of your personal information.
    </p>

    <p>As of January 1, 2023 you have the right to opt-out of sharing your personal information with third parties for
      the purposes of targeted behavioral advertising, which you can do by emailing us at <a
        href="mailto:support@careride.com" target="_blank">support@careride.com</a>.
      CareRide collects various categories of personal information when you use the Service, including identifiers,
      commercial information, internet or other electronic network or device activity information, geolocation data,
      and professional information. A more detailed description of the information CareRide collects and how we use it
      is provided above under the caption Information We Collect and How We Use It. The caption “Third Parties”
      describes the categories of third parties with whom we share personal information, and what information may be
      shared under different circumstances</p>

    <p> If you are a resident of California, you have the right to request to know what personal information has been
      collected about you, and to access that information. You also have the right to request deletion of your
      personal information, though exceptions under the CCPA may allow CareRide to retain and use certain personal
      information notwithstanding your deletion request. As of January 1, 2023 you have the right to request
      correction of inaccurate personal information and to request to limit disclosure or use of your sensitive
      personal information. Please note, however, that any right to request correction is limited by the legal rights
      of CareRide and its users relating to content on CareRide's platform, including without limitation legally
      protected rights related to freedom of speech, freedom of the press, or freedom to information. You may send
      your request by email to <a href="mailto:support@careride.com" target="_blank">support@careride.com</a>.
      Information on the number of requests CareRide has received in
      California is available through <a href="mailto:support@careride.com" target="_blank">support@careride.com</a>.
      We will not discriminate against you for exercising your
      rights under the CCPA. An agent may submit a request on your behalf, but you must verify that your agent is
      authorized to do so. CareRide does not knowingly sell or share the personal information of consumers under 16
      years of age. Separate from the CCPA, California's "<b>Shine the Light</b>" law (Civil Code Section § 1798.83)
      permits users of our App that are California residents to request certain information regarding our disclosure
      of personal information to third parties for their direct marketing purposes. To make such a request, please
      send an email to <a href="mailto:support@careride.com" target="_blank">support@careride.com</a> or write to us
      at the address above under “Contact Information”. </p>

  </div>
</template>

<script>
export default {
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
<style lang="scss"></style>
